
.rich-text-img {
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;
    .el-icon-picture {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #D7D7D7;
        font-size: 16px;
        transition: color .3s;
        &:hover {
            color: #006aff;
        }
        &.disabled {
            color: #eeeddd;
            cursor: not-allowed;
        }
    }
    .file-input {
        display: none;
    }
}
