
.coupon-create {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    .form-box {
        box-sizing: border-box;
        width: 100%;
        padding: 30px 20px;
        border-bottom: 1px solid #E8E6EB;
        .hint {
            font-size: 12px;
            color: #8C8C8C;
        }
    }
    .select-goods {
        box-sizing: border-box;
        flex: 1;
        padding: 16px 20px 0;
    }
    .bottom-operation {
        padding: 10px 0;
        text-align: center;
        .send-btn {
            width: 119px;
            background-color: #006AFF;
            border: none;
            &:hover {
                background-color: #3d8eff;
            }
        }
    }
}
::v-deep .money-input {
    margin: 0 6px;
    width: 75px;
}
::v-deep .el-form-item__label {
    padding-right: 20px;
}
::v-deep .el-form-item__label::before {
    display: none;
}
