
.rich-text {
    flex: 1;
    .expand-toolbar {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        background-color: #F5F5F5;
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin-left: 3px;
            cursor: pointer;
            color: #666666;
        }
        .icon-box {
            border-radius: 4px;
            transition: all .3s;
            &:hover {
                background-color: #eeeddd;
            }
            &:nth-child(1) .iconfont {
                font-size: 18px;
            }
            &:nth-child(2) .iconfont {
                font-size: 14px;
            }
            &:nth-child(3) .iconfont {
                font-size: 18px;
            }
            &.active {
                background-color: #ddd;
            }
        }
        
        .decorate {
            width: 1px;
            height: 17px;
            background-color: #999999;
        }
    }
}
::v-deep p {
    margin: 0;
}
::v-deep .tox-tinymce {
    border: none !important;
}
::v-deep .expand-toolbar {
    .iconfont,
    .el-icon-picture {
        color: #666666;
    }
}
/* ::v-deep .tox-editor-header {
    height: 40px !important;
    border-bottom: none !important;
    background-color: #F5F5F5 !important;
} */
