
$themeColor: #006AFF;
.blue-font {
    margin-left: 16px;
    color: $themeColor;
    font-size: 14px;
}
.fast-reply {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 287px;
    min-width: 287px;
    height: 100%;
    margin-right: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #EAECF0;
    border-left: none;
    background-color: #fff;
    overflow: hidden;
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,.3);
        z-index: 9;
    }
    .title {
        box-sizing: border-box;
        height: 68px;
        color: #606266;
        text-align: center;
        line-height: 67px;
        border-bottom: 1px solid #EAECF0;
    }

    .search-input {
        box-sizing: border-box;
        display: flex;
        padding: 10px;
        width: 100%;
        overflow: hidden;
        ::v-deep .el-input__inner {
            flex: 1;
            height: 30px;
        }
    }

    .main {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        overflow: hidden;
        .scroll-container {
            flex: 1;
            box-sizing: border-box;
            padding: 0 10px;
            overflow: hidden;
            .group-item {
                position: relative;
                box-sizing: border-box;
                width: 100%;
                margin-bottom: 10px;
                /* overflow: hidden; */
                &:last-child {
                    margin-bottom: 0;
                }
                
                .header {
                    display: flex;
                    color: #606266;
                    font-size: 14px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    .icon {
                        margin-right: 10px;
                        font-size: 16px;
                        color: #606266;
                        font-weight: 700;
                        transition: transform .3s;
                        &.open {
                            transform: rotate(90deg);
                        }
                    }
                    .group-title {
                        margin-right: 3px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .count {
                        display: flex;
                        align-items: center;
                    }
                }
                .reply-list {
                    box-sizing: border-box;
                    display: none;
                    &.open {
                        display: block;
                    }
                    .reply-item {
                        box-sizing: border-box;
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 32px;
                        padding-left: 20px;
                        background-color: #fff;
                        transition: all .3s;
                        cursor: pointer;
                        &:hover {
                            color: $themeColor;
                            background-color: #F6F7FB;
                        }
                        .fast-coding {
                            margin-right: 4px;
                            color: #006aff;
                        }
                        .reply-content {
                            flex: 1;
                            color: #1E2226;
                            overflow: hidden;
                            .content {
                                width: 100%;
                                line-height: 23px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .tooltip {
                                box-sizing: border-box;
                                display: none;
                                position: absolute;
                                top: 100%;
                                left: 10%;
                                right: 0;
                                width: fit-content;
                                max-width: 80%;
                                padding: 5px 10px;
                                border-radius: 4px;
                                word-wrap: break-word;
                                background-color: #f0edf1;
                                z-index: 99;
                                border: 1px solid #ddd;
                            }
                            &:hover .tooltip {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-operation {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 24px;
        .operation-list {
            display: flex;
            width: 110px;
            justify-content: space-between;
            .item {
                color: #606266;
                transition: color .3s;
                &:hover {
                    color: $themeColor;
                }
            }
            .decorate {
                line-height: 18px;
            }
        }
        .create-reply {
            color: $themeColor;
        }
        ::v-deep .el-button {
            padding: 0;
        }
    }
    .rich-text-form {
        display: flex;
        flex-direction: column;
        /* border: 1px solid #E3E2E5; */
        border-radius: 4px;
        &.el-input__inner {
            padding: 0;
            height: fit-content;
        }
        .rich-text-expand {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 100%;
            height: 38px;
            padding: 12px 10px 12px 17px;
        }
    }
    .rich-text-hint {
        margin-top: 8px;
        color: #AEB2B9;
        font-size: 12px;
        line-height: 12px;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
        display: flex;
        min-height: 100%;
        .scroll-container {
            min-height: 100%;
        }
    }
}
::v-deep .el-dialog__wrapper {
    .el-dialog__header {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 72px;
        padding: 0 30px;
        border-bottom: 1px solid #E6E7EB;
        .el-dialog__title {
            font-size: 16px;
            color: #606266;
        }
        .el-dialog__headerbtn {
            position: none;
            .el-dialog__close::before {
                font-size: 20px;
            }
        }
    }
    .el-dialog__body {
        padding: 30px !important;
    }
    .el-dialog__footer {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100px;
        padding: 0 20px;
        border-top: 1px solid #E6E7EB;
        .dialog-footer {
            display: flex;
            .el-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 96px;
                height: 40px;
                font-size: 14px;
                word-spacing: -2px;
            }
            .el-button--default {
                color: #606266;
                border-color: #B0B0BA;
                &:hover {
                    color: #fff;
                    background-color: rgba(0, 106, 255, 1);
                    border: none;
                }
            }
            .el-button--primary {
                background-color: #006AFF;
                border: none;
                &:hover {
                    background-color: rgba(0, 106, 255, .8);
                }
            }
        }
    }
}
@keyframes ShowGroup {
    0% {
        width: 0;
    }
    100% {
        width: 180px;
    }
}
::v-deep .group-input {
    width: 0;
    margin-left: 10px;
    animation: ShowGroup .3s ease 0s 1 normal forwards;
}

::v-deep .el-form .el-form-item:last-child {
    margin-bottom: 0;
}
::v-deep p {
    display: flex;
    align-items: center;
    margin: 0 !important;
}
::v-deep .group-form-item {
    .el-form-item__content {
        display: flex;
        .group-form .el-form-item__error {
            margin-left: 10px;
        }
    }
}

.edit-group {
    display: flex;
    flex-direction: column;
    .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        font-size: 14px;
        color: #fff;
        background: linear-gradient(91deg, #006aff 0%, #00B8FF 100%);
        .iconfont {
            font-size: 14px;
            cursor: pointer;
        }
    }
    .group-form {
        padding: 10px 15px;
    }
    .bottom-operation {
        padding: 0 15px 15px;
        text-align: right;
        .confirm {
            background-color: #006aff;
            border-color: #006aff;
        }
    }
}
::v-deep p {
    display: inline;
    margin: 0;
}
