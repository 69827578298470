
.client-order {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    overflow: hidden;
    .state-tab {
        display: flex;
        padding: 0 20px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #006AFF;
        font-weight: bold;
        .finished {
            margin-left: 30px;
            color: #606266;
            font-weight: normal;
        }
    }
    .main {
        box-sizing: border-box;
        padding: 0 10px;
        .target-goods {
            font-size: 14px;
            margin-bottom: 20px;
            .hint {
                margin-bottom: 10px;
                color: #8C8C8C;
                .username {
                    color: #1E2226;
                }
            }
        }
        .coupon-list {
            width: 100%;
            .coupon-item {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .check-order {
            width: 100%;
            font-size: 14px;
            color: #1E2226;
            .order-info {
                width: 100%;
                border: 1px solid #E6E7EB;
                border-radius: 4px;
                margin-bottom: 10px;
                .base-info {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 16px;
                    border-bottom: 1px solid #E6E7EB;
                    background-color: #F8F8FA;
                    .item {
                        margin-bottom: 6px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .main-info {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 10px;
                    .purchaser-info {
                        margin-bottom: 10px;
                        .goods-price {
                            margin-bottom: 6px;
                        }
                    }
                }
            }
            .check-info {
                box-sizing: border-box;
                width: 100%;
                padding: 16px;
                border: 1px solid #E6E7EB;
                border-radius: 4px;
                .check-btn {
                    float: right;
                    width: 80px;
                    height: 28px;
                    padding: 0;
                    padding-left: 1px;
                    color: #006AFF;
                    text-align: center;
                    border: 1px solid #006AFF;
                }
                .purchaser-info {
                    width: 100%;
                    margin-bottom: 16px;
                    .item {
                        margin-bottom: 6px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .parameter-info {
                    display: flex;
                    font-size: 12px;
                    color: #8C8C8C;
                    .buy-count {
                        margin-right: 14px;
                    }
                }
            }
        }
    }
}
::v-deep el-scrollbar__wrap {
    overflow-x: hidden;
}
