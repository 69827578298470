
.contextmenu {
    float: right;
    .menu-list {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 100px;
        font-size: 14px;
        color: #333;
        background-color: #f0edf1;
        z-index: 9;
        border-radius: 4px;
        overflow: hidden;
        outline: none;
        .menu-item {
            display: flex;
            align-items: center;
            height: 35px !important;
            padding: 0 10px;
            transition: all .3s;
            cursor: pointer;
            &:hover {
                color: #fff;
                background-color: #006aff;
            }
        }
    }
}
