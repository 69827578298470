
$themeColor: #006AFF;
.goods-item {
    box-sizing: border-box;
    display: flex;
    max-width: 100%;
    height: 76px;
    padding: 8px 6px;
    margin-bottom: 16px;
    font-size: 12px;
    color: #606266;
    background-color: #f7f6fb;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    &:last-child {
        margin-bottom: 0;
    }
    .custom-img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        vertical-align: middle;
        cursor: pointer;
    }
    .info-box {
        flex: 1;
        overflow: hidden;
        .goods-name {
            width: 100%;
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
        }
        .price-box {
            display: flex;
            margin-top: 6px;
            .price {
                margin-right: 20px;
            }
            .money {
                color: #EC3535;
            }
        }
    }
    .operation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .icon {
            font-size: 14px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: $themeColor;
            }
        }
        
    }
}
