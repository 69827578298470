
.emoji {
    position: relative;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    .iconfont {
        display: flex;
        color: #D7D7D7;
        font-size: 16px;
        line-height: 16px;
        transition: color .3s;
    }
    &:hover .iconfont{
        color: #006AFF;
    }
    .emoji-modal {
        position: absolute;
        display: grid;
        grid-template-columns: repeat(auto-fill, 40px);
        grid-template-rows: repeat(6, 40px);
        justify-items: center;
        align-items: center;
        width: 482px;
        padding: 5px 0;
        left: 50%;
        bottom: calc(100% + 18px);
        transform: translateX(-50%);
        background-color: #fff;
        border: 1px solid #E3E2E5;
        outline: none;
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
            width: 15px;
            height: 15px;
            border-width: 0 1px 1px 0;
            border-color: #E3E2E5;
            border-style: solid;
            background-color: #fff;
        }
        .emoji-item {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}
